import Vue from 'vue'
import Router from 'vue-router'

// Use import for the default route
import VisitorHome from '@/views/visitor/Home.vue';

// Use import() for all others
const Console = () => import('@/views/Console.vue');
const CustomerQuotes = () => import('@/views/customer/Quotes.vue');
const CustomerCustoms = () => import('@/views/customer/Customs.vue');
const Password = () => import('@/views/Password.vue');

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'visitor',
      component: VisitorHome
    },
    {
      path: '/index.html',
      component: VisitorHome
    }, // Fix for PWA at /index.html
    {
      path: '/password',
      name: 'password',
      component: Password,
    },
    {
      path: '/console',
      name: 'console',
      component: Console,
      children: [
        {
          path: 'quotes',
          name: 'quotes',
          component: CustomerQuotes,
        },
        {
          path: 'customs',
          name: 'customs',
          component: CustomerCustoms,
        },
      ]
    }
  ]
})
