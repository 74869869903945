module.exports = {
  'en-US': {
    message: {
      menu: {
        company: 'Company',
        whyUs: 'Why Us',
        mission: 'Mission',
        contact: 'Contact',
        resources: 'Resources',
        signIn: 'Sign In'
      },
      banner: {
        title: 'Service is Paramount',
        start: 'GET STARTED'
      },
      company: {
        p1: 'Have you had experience of needing a US trucking rate immediately, sending quotes to different overseas agents or carriers to inquire, and waiting for overnights or days to get rates?  Look4Trucks is tailored according to you needs.',
        p2: 'We make quoting Full Truckload (FTL) freight shipments fast and easy. Fill in the destination city and zip, Hub or Terminal, Vessel Company or Rail, Cargo Weight and Type, Container Type, then you will be able to see the rate quotes with a map showing you the route and distance. Accessories will be listed separately too for your reference.  For heavy or Hazmat shipment, it also has a quote selection.',
        p3: 'For Amazon, and other e-commerce sites supply shipments, regardless of floor-loading or palletized cargo, as long as you can provide dimension and weight of packages, volume, type, etc.  the quote can be provided too.  Further LTL services can be further provided with additional service inquiry.',
        p4: 'For Costco, Mernards, Walmart, or other distribution shipments for big supply chains, based on the requirements of them, and the service you choose, you’ll gain more competitive advantage from having direct access to our quick online platform to plan, budget and execute your shipment.'
      },
      whyUs: {
        p1: 'Our extensive network of carrier partners, strong industry expertise, and long-term relationship network will provide you more confidence to form a partnership with us and grow together.',
        forAgent: 'For Designated Overseas Agent',
        forCarrier: 'For Our Carriers',
        list1: [
          'We work with the trustworthy carriers in the industry to move your freight, you will have stable service.',
          'Our sales team works closely with those carriers to make sure you get the competitive rates truckload and other needed services.',
          'Our team will share our expertise and professional knowledge with you to facilitate us to grow together.',
          'We continuously expand our network by closely working with our clients to meet their needs.',
          'You have your designated live representative with almost 24 x 7 help.'
        ],
        p2: 'L4T has designed their own system with latest technology and industry uniqueness to provide you easy ways to get quote.',
        list2: [
          'Quote a shipment online or on your smartphone',
          'Schedule your shipment and pickup',
          'Track your shipment from origin to destination',
          'See your shipping history'
        ],
        list3: [
          'We believe in Win-Win standards in all transactions. No worry about less profit.',
          'We know you need to pay your trucker right after the job. No worry about Cash Flow.',
          'We feed you client year round. No worry about stand by drivers waiting for work.',
          'We help each other improve operation and gain more stable and profitable business for mutual growth.'
        ]
      },
      mission: {
        paragraph: [
          'Look4Trucks is a platform that facilitate the overseas freight forwarding agent looking for appropriate FCL trucking in United States.  Located in Los Angeles, California, Look4Trucks.com is targeting to make the freight service more crystal, swift and efficient. ',
          'It also aims at educating our designated agents about the intermodal transportation of the United States, communicate with them about the seasonal terminal, hub, weather, labor related situation with U.S. inland transportation. ',
          'Our Mission and Vision is to grow with our client by providing them more timely service in rate quoting, budgeting, planning, and cost controlling. '
        ]
      },
      contact: {
        email: 'Email',
        address: 'Address',
        tel: 'Tel',
      },
      resources: {
        shippingTerms: 'Shipping Terms',
        termItems: [
          [
            {
              content: '<strong>Accessorial Charges</strong><br /> Any additional services required outside of the standard shipping procedure require additional fees from the carrier called accessorials. These include extra services such as:',
              asItems: [
                'Lift gate service',
                'Inside pick up',
                'Residential service',
                'Collect on delivery (COD)',
                'Fuel surcharge',
                'Inside delivery',
                'Arrival notification',
                'Insurance',
              ],
            },
            {
              content: '<strong>Back Haul</strong><br /> A return load. Instead of returning to a load&rsquo;s origin with an empty trailer, a carrier will find another load (the "back haul") going back to the original location.',
            },
            {
              content: '<strong>Bill of Lading (BOL)</strong><br /> This is the legal document that shows that the carrier has received the freight as described, and is obligated to deliver that freight in good condition to the consignee. One BOL is required for each shipment.',
            },
            {
              content: '<strong>Bobtail</strong><br /> A tractor operating without a trailer attached.',
            },
            {
              content: '<strong>Bulk Freight</strong><br /> Freight that is not in packages or containers; normally hauled in tankers, grain trailers, and sometimes in regular van trailers.',
            },
            {
              content: '<strong>Box</strong><br /> A less formal name for a shipping container.',
            },
          ],
          [
            {
              content: '<strong>Carrier</strong><br /> Any individual, company or corporation engaged in transporting goods.'
            },
            {
              content: '<strong>Cabover</strong><br /> Short for cab-over-engine, designed so that the cab sits over the engine on the chassis.'
            },
            {
              content: '<strong>Cargo Claim</strong><br /> A claim for payment for goods lost or damaged while the shipment was in the carrier&rsquo;s possession. Cargo claims must be filed within nine months.'
            },
            {
              content: '<strong>Collect on Delivery (COD)</strong><br /> A shipment for which the transportation provider is responsible for collecting the sale price of the goods shipped before delivery. The additional administration required for this type of shipment necessitates an additional fee to cover the carrier&rsquo;s cost.'
            },
            {
              content: '<strong>Common carrier</strong><br /> A company that provides transportation services to the public in return for compensation.'
            },
            {
              content: '<strong>Concealed loss / concealed damage</strong><br /> Shortage or damage not evident at delivery.'
            },
            {
              content: '<strong>Consignee</strong><br /> The person or place to whom the goods are addressed and the final destination where a shipment will be transferred for the last time.'
            },
            {
              content: '<strong>Cartage Company</strong><br /> A carrier that provides local pickup and delivery.'
            },
            {
              content: '<strong>CDL (Commercial Drivers License)</strong><br /> The operator&rsquo;s license which authorizes individuals to operate commercial motor vehicles over 26,000 pounds gross vehicle weight.'
            },
            {
              content: '<strong>Check Call</strong><br /> Checking in with your company/dispatcher to inform them of your progress, and any other important information.'
            },
            {
              content: '<strong>Clearance Lights</strong><br /> The lights on top of the front and rear of the trailer; often referred to as the marker lights.'
            },
            {
              content: '<strong>Container</strong><br /> A standard sized metal box used to transport freight. International shipping containers are 20 to 40 feet long, and can be transported in an ocean liner, on rail cars and on public roads on a container chassis trailer. Domestic containers are up to 53 foot long, and are of lighter construction.'
            },
            {
              content: '<strong>Container Chassis</strong><br /> A type of trailer specifically designed to carry a shipping container.'
            },
            {
              content: '<strong>Consignee</strong><br /> The receiver, who accepts your delivery.'
            },
            {
              content: '<strong>Carrier Liability</strong><br /> A common carrier is liable for shipment loss, damage, and delay with the exception of that caused by act of God, act of a public enemy, act of a public authority, act of the shipper, and the goods&rsquo; inherent nature.'
            },
            {
              content: '<strong>Class I Carrier</strong><br /> A classification of carriers based upon annual operating revenues of more than $5 million.'
            },
            {
              content: '<strong>Class II Carrier</strong><br /> A classification of carriers based upon annual operating revenues of $1 - $5 million.'
            },
            {
              content: '<strong>Class III Carrier</strong><br /> A classification of carriers based upon annual operating revenues of less than $1 million.'
            },
            {
              content: '<strong>Cube</strong><br /> Capacity, measured in cubic feet, of the interior of a trailer.'
            },
          ],
          [
            {
              content: '<strong>Density</strong><br /> The relationship of the weight of a shipment to the physical space it occupies.'
            },
            {
              content: '<strong>Drayage</strong><br /> The charge made for local handling of freight. Common in tradeshow shipping.'
            },
            {
              content: '<strong>Deadhead</strong><br /> Driving a tractor-trailer without cargo, or a paying load.'
            },
            {
              content: '<strong>Detention/demurrage</strong><br /> Penalty charges assessed by a carrier to a shipper or consignee for holding equipment longer than the stipulated time for loading or unloading.'
            },
            {
              content: '<strong>Dock Lock</strong><br /> A safety device that hooks to a trailer&rsquo;s bumper when backed to a loading dock to prevents the trailer from pulling away while a forklift driver and anyone else is inside the trailer.'
            },
            {
              content: '<strong>Drop and Hook</strong><br /> Taking a loaded trailer to a shipper/receiver, dropping the trailer and then leaving with a loaded trailer.'
            },
            {
              content: '<strong>Dry Freight</strong><br /> Freight that&rsquo;s not refrigerated.'
            },
            {
              content: '<strong>Fingerprinting</strong><br /> When a driver has to unload the trailer by himself.'
            },
            {
              content: '<strong>Freight Lane</strong><br /> The route on which a large amount of freight flows back and forth. Frequently just called a &ldquo;Lane.&rdquo;'
            },
            {
              content: '<strong>Free on board (FOB)</strong><br /> The seller agrees to deliver merchandise, free of transportation expense, to the place specified by the contract.'
            },
            {
              content: '<strong>FOB destination</strong><br /> Under this arrangement, title and risk remain with the seller until it has delivered the goods to the location specified in the contract.'
            },
            {
              content: '<strong>FOB origin</strong><br /> Title and risk pass to the buyer at the moment the seller delivers the goods to the carrier.'
            },
            {
              content: '<strong>Freight bill</strong><br /> A shipping document used to confirm shipment delivery and indicate payment terms (prepaid or collect) and that describes the shipment.'
            },
            {
              content: '<strong>Freight Broker</strong><br /> A company that arranges for the transportation of freight belonging to others, utilizing for-hire carriers to provide the actual transportation. Brokers do not assume responsibility for the cargo and usually do not take possession of the freight.'
            },
            {
              content: '<strong>Freight forwarder</strong><br /> A freight forwarder combines less-than-truckload (LTL) or less-than-container-load (LCL) shipments into full container or truckload lots. Freight forwarders are designated as common carriers and can issue bills of lading and accept responsibility for goods.'
            },
            {
              content: '<strong>FCL (Full Container-Load)</strong><br /> A full container-load shipment is when a shipper contracts for the transportation of an entire container. The vast majority of intermodal freight is contracted in this manner.'
            },
          ],
          [
            {
              content: '<strong>GAWR (Gross Axle Weight Rating)</strong><br /> The maximum weight an axle is rated to carry by the manufacturer.'
            },
            {
              content: '<strong>GCW (Gross Combination Weight)</strong><br /> The total weight of a loaded combination vehicle, such as a tractor-trailer.'
            },
            {
              content: '<strong>GVW (Gross Vehicle Weight)</strong><br /> The total weight of a vehicle; the vehicle&rsquo;s weight, and the contents of the trailer and tractor.'
            },
            {
              content: '<strong>GVWR (Gross Vehicle Weight Rating)</strong><br /> The total weight a vehicle is rated to carry by the manufacturer, including it&rsquo;s own weight and the weight of the freight.'
            },
            {
              content: '<strong>Hazmat</strong><br /> Hazardous materials as classified by the United States Environmental Protection Agency (EPA). Transportation of hazardous materials is regulated by the United States Department of Transportation.'
            },
            {
              content: '<strong>Hundredweight / CWT</strong><br /> 100 pounds. A common shipping weight unit.'
            },
            {
              content: '<strong>Intermodal Shipping</strong><br /> A system that uses standard-sized containers that can be moved between different modes of transport, such as ships, trucks and trains. Freight does not need to be unloaded every time it is moved from one mode to another.'
            },
            {
              content: '<strong>Inside Pickup / Inside Delivery</strong><br /> If a driver is required to go inside (beyond the front door or loading dock), to pick up or deliver a shipment, instead of remaining at the dock or truck, it is considered an inside pickup or delivery.'
            },
            {
              content: '<strong>Jake Brake&nbsp;</strong><br /> An engine retarder which helps to slow vehicles.'
            },
            {
              content: '<strong>LCL (Less-than-Container-Load)</strong><br /> An ocean-shipping and intermodal industry term; LTL equivalent in container shipping. Container freight stations at ports serve as consolidation and deconsolidation terminals. Historically, LCL also stands for less-than-carload. Before the prominence of interstate trucking, railroads offered less-than-carload (LCL) service but this business has largely disappeared.'
            },
            {
              content: '<strong>Landing Gear</strong><br /> Retracting legs which support a trailer when it&rsquo;s not connected to a tractor.'
            },
            {
              content: '<strong>Layovers</strong><br /> Any off-duty time while away from home.'
            },
            {
              content: '<strong>Loaded Call</strong><br /> The call made to a dispatcher from the shipper&rsquo;s location once the trailer is loaded and the bills are signed.'
            },
            {
              content: '<strong>Lift Gate Service</strong><br /> When the shipping or receiving address does not have a loading dock, manual loading or unloading is necessary. A lift gate is a platform at the back of certain trucks that can raise and lower a shipment from the ground to the truck. Additional fees apply for this service.'
            },
            {
              content: '<strong>Linehaul</strong><br /> Moving freight from one point to another.'
            },
            {
              content: '<strong>LTL (Less-Than-Truckload)</strong><br /> A quantity of freight less than that required for the application of a truckload rate, usually less than 10,000 pounds. These smaller loads are consolidated by an LTL carrier into one vehicle headed for multiple destinations.'
            },
            {
              content: '<strong>LCV (Long Combination Vehicle)</strong><br /> Any combination of a truck and two or more trailers which operate on the Interstate System with a gross vehicle weight (GVW) greater than 80,000 pounds.'
            },
            {
              content: '<strong>Low Boy</strong><br /> An open flatbed trailer, where the main body of the trailer is very low to the ground so that it can haul oversize or wide loads; often construction equipment, or other extremely bulky or heavy loads.'
            },
          ],
          [
            {
              content: '<strong>Minimum charge</strong><br /> The lowest charge for a shipment after discount and/or adjustment.'
            },
            {
              content: '<strong>NMFC Number</strong><br /> This is the item number that specifically identifies each type of product that can be shipped by an LTL carrier. The National Motor Freight Traffic Association (NMFTA) assigns NMFC item #&rsquo;s to all products along with a LTL freight class. The NMFC # identifies what you are shipping and its LTL freight class.'
            },
            {
              content: '<strong>Non-vessel operating common carriers (NVOCC)</strong><br /> An ocean freight forwarder. NVOCCs book space in large quantities for a reduced rate, then sell space to shippers in smaller amounts.'
            },
            {
              content: '<strong>No-Touch</strong><br /> A situation in which the driver doesn&rsquo;t have to load or unload the freight.'
            },
            {
              content: '<strong>Overcharge Claim</strong><br /> A claim for a refund of an overcharge from the erroneous application of rates, weights and/or assessment of freight charges.'
            },
            {
              content: '<strong>Out of Route</strong><br /> Carriers usually use set mileage amounts for distances between cities. If a driver goes over this amount, any miles over are considered "out of route" miles.'
            },
            {
              content: '<strong>Overage</strong><br /> Extra freight which shouldn&rsquo;t have been shipped.'
            },
            {
              content: '<strong>Owner-Operator&nbsp;</strong><br /> A driver who&rsquo;s in business for himself and owns and operates his own trucks, trailers, or other equipment.'
            },
            {
              content: '<strong>Pallet / Skid</strong><br /> A small platform, 40&times;48 inches usually, on which goods are placed for handling and shipping.'
            },
            {
              content: '<strong>P&D</strong><br /> Pickup and delivery.'
            },
            {
              content: '<strong>Pay Load</strong><br /> The weight of the freight being shipped.'
            },
            {
              content: '<strong>PRO or Tracking Number</strong><br /> A number assigned by the carrier to reference your shipment. It is also used for tracking your shipment.'
            },
            {
              content: '<strong>Peddle Run</strong><br /> A load which has multiple and often frequent deliveries.'
            },
          ],
          [
            {
              content: '<strong>Reweigh and Inspection Fee</strong><br /> If the carrier suspects that the weight or class of your shipment is inaccurate, they will charge a fee to reweigh and re-class your shipment.'
            },
            {
              content: '<strong>Reefer</strong><br /> A refrigerated trailer, where the temperature is controlled by a refrigeration unit.'
            },
            {
              content: '<strong>Seal</strong><br /> A plastic or metal band (once broken, cannot be reattached) placed on the trailer door latch to ensure the doors have not been opened and the cargo is untouched.'
            },
            {
              content: '<strong>Spotter</strong><br /> A driver who moves and parks trailers in a terminal yard.'
            },
            {
              content: '<strong>Shipper / Consignor</strong><br /> Person or organization paying for freight to be shipped from one place to another.'
            },
            {
              content: '<strong>Shortage</strong><br /> The number of units received is less than the quantity shown on shipping documents.'
            },
            {
              content: '<strong>3PL</strong><br /> A third-party logistics company, to which logistics services are outsourced. Typically handles purchasing, scheduling and billing for freight shipped.'
            },
            {
              content: '<strong>Tare Weight</strong><br /> The weight of a container and the packing material less the weight of its content.'
            },
            {
              content: '<strong>Tariff</strong><br /> A document detailing rules, rates and charges to move freight and sets forth a contract for the shipper, the consignee and the carrier. Carriers are not required to publish tariffs but must be provided to a shipper on request.'
            },
            {
              content: '<strong>TL (Truckload)</strong><br /> A quantity of freight sufficient to fill a trailer, usually greater than 10,000 pounds.'
            },
            {
              content: '<strong>TL Carrier</strong><br /> A carrier that dedicates trailers to a single shipper&rsquo;s freight, as opposed to an LTL carrier, which often transports the combined freight of several different shippers.'
            },
            {
              content: '<strong>Volume Rate</strong><br /> A rate applicable in connection with a specified volume (weight) of freight.'
            },
          ],
        ],
        usModel: 'Intermodals of US',
        modelDesc: 'Below is a listing of those cities with at least&nbsp;two intermodal terminals and which railroad(s) operate the facilities. Some may be joint (or duplicate) facilities.&nbsp;<strong>Updates shown in bold.</strong>',
      },
    }
  },
  'zh-CN': {
    message: {
      menu: {
        company: '公司简介',
        whyUs: '选择理由',
        mission: '使命',
        contact: '联系方式',
        resources: '资源',
        signIn: '登录'
      },
      banner: {
        title: '服务至高无上',
        start: '开始'
      },
      company: {
        p1: '您是否有过这样的经历：因为客人立即需要美国本土卡车运货费、而向不同的海外代理或运营商发送报价以便查询、由于时差，等待通宵，或者要等到第二天才能拿到费率，而因此错过客户? Look4Trucks是根据您的需要量身定做的。',
        p2: '我们使得整箱卡车(FTL)货运的报价变得更快速和容易。填写目的地城市和邮政编码、铁路枢纽或码头、船舶公司或铁路、货物重量和类型、集装箱类型，然后您将能够看到报价与地图显示您的路线和距离。杂费也会单独列出，供您参考。对于重型或危险品运输，您也有一个报价选择。',
        p3: '对于亚马逊等电子商务网站提供发货，无论是无托盘散装还是托盘货，只要能提供包裹的尺寸和重量、体积、类型等，美国国内的卡车报价也可以提供。可以通过额外的服务查询，进一步提供更多的散货卡车（LTL）报价服务。',
        p4: '对于Costco、Mernards、Walmart或其他大型供应链的配送发货，根据它们的需求和您选择的服务，您将通过直接访问我们的快速在线平台来计划、预算和执行您的货物运送，从而获得更大的竞争优势。'
      },
      whyUs: {
        p1: '我们广泛的运营商合作伙伴网络，强大的行业专业知识和长期的关系网络将为您提供更多的信心与我们建立伙伴关系，共同成长。',
        forAgent: '海外代理',
        forCarrier: '运营商',
        list1: [
          '为和我们建立指定合作关系的海外代理服务，我们与业内值得信赖的承运人合作，为客户运送货物，将有稳定的服务。',
          '我们的销售团队与这些航空公司紧密合作，以确保我们的代理客户得到具有竞争力的运价和其他所需的服务。',
          '我们的团队将与您分享我们的专业知识和专业知识，促进我们共同成长。',
          '我们不断拓展我们的运营商合作网络，与我们的客户紧密合作，以满足他们的需求。',
          '我们的代理客户都有给您的指定的操作，与几乎每周7天24小时的帮助。'

        ],
        p2: 'L4T设计了自己的系统，具有最新的技术和行业的独特性，为您提供方便的报价方式。',
        list2: [
          '在线或智能手机报价，确认价格',
          '安排发货和提货',
          '跟踪您的发货从原点到目的地',
          '查看您的发货历史'
        ],
        list3: [
          '我们相信在所有交易中双赢的标准。',
          '不用担心利润减少。我们知道你们需要在工作结束后马上给你们的卡车司机发工资。不用担心现金流。',
          '我们常年为您服务。不用担心等待工作的司机。',
          '我们互相帮助，提高经营水平，获得更稳定、更盈利的业务，共同成长。'
        ]
      },
      mission: {
        paragraph: [
          'Look4Trucks是一个平台，方便海外货运代理寻找美国本土合适的整箱货运价格。位于加州洛杉矶的Look4Trucks.com，旨在使货运服务更加透明、快捷和高效。',
          '也旨在让我们指定的海外代理更了解美国的多式联运，与他们沟通美国内陆运输的季节性码头、枢纽、天气、劳工相关情况。',
          '我们的使命和愿景是与客户共同成长，为他们提供更及时的报价、预算、计划和成本控制服务。'
        ]
      },
      contact: {
        email: '邮箱',
        address: '地址',
        tel: '电话',
      },
      resources: {
        shippingTerms: '货运术语',
        termItems: [
          [
            {
              content: '<strong>Accessorial Charges</strong><br /> 附加费用，在标准运输程序之外需要的任何额外服务都需要从承运人收取额外的费用，称为访问费。这些额外服务包括:',
              asItems: [
                '升降机服务',
                '上门收件服务',
                '居民区服务',
                '货到付款服务',
                '燃油附加费',
                '送货入室服务',
                '通知书、保险、预提柜',
                '仓储服务',
                '等候时间及塞港费',
              ]
            },
            {
              content: '<strong>Back Haul</strong><br /> 回程拉回负载。承运人不会带着空拖车返回货物的原点，而是会发现另一个货物(“回程”)返回到原来的位置。',
            },
            {
              content: '<strong>Bill of Lading (BOL)</strong><br /> 提单(BOL)这是一种法律文件，表明承运人已经收到了上述货物，并有义务将货物完好无损地交付给收货人。每批货需要一个提单。',
            },
            {
              content: '<strong>Bobtail</strong><br /> 挂车一种没有挂车的拖拉机。货运中通常指货物被drop以后，卡车单独开回去拿空箱的情况。',
            },
            {
              content: '<strong>Bulk Freight</strong><br /> 未采用包装或者集装箱运输的散装货物;通常用油罐车、谷物拖车拖运，有时用普通货车拖车拖运。',
            },
            {
              content: '<strong>Box</strong><br /> 是集装箱的不太正式的名称。',
            },
          ],
          [
            {
              content: '<strong>Carrier</strong><br /> 承运人从事货物运输的任何个人、公司或公司。'
            },
            {
              content: '<strong>Cabover</strong><br /> Cabover是cab-over-engine的缩写，其设计使驾驶室位于底盘上的引擎之上。'
            },
            {
              content: '<strong>Cargo Claim</strong><br /> 货物索赔对货物在承运人占有期间灭失或损坏的赔偿要求。货物索赔必须在9个月内提出。'
            },
            {
              content: '<strong>Collect on Delivery (COD)</strong><br /> 货到付款(货到付款):运输供应商负责在货物交付前收取货物的销售价格的一种货物。这类货物的额外管理需要额外的费用来支付承运人的费用。'
            },
            {
              content: '<strong>Common carrier</strong><br /> 向公众提供运输服务而收取利润的公司。'
            },
            {
              content: '<strong>Concealed loss / concealed damage</strong><br /> 交货时未见明显短缺或损坏。'
            },
            {
              content: '<strong>Consignee</strong><br /> 最终装运货物的收件人或地点，以及最终物的目的地。'
            },
            {
              content: '<strong>Cartage Company</strong><br /> 提供本地取货和送货的承运人。'
            },
            {
              content: '<strong>CDL (Commercial Drivers License)</strong><br /> 允许个人驾驶总重量超过2.6万磅的商用机动车的《机动车驾驶证》。'
            },
            {
              content: '<strong>Check Call</strong><br /> 调度员通知他们你的进度，以及任何其他重要信息。'
            },
            {
              content: '<strong>Clearance Lights</strong><br /> 拖车前后顶部的灯; 通常被称为标记灯。'
            },
            {
              content: '<strong>Container</strong><br /> 用于运输货物的标准尺寸的金属盒子。国际集装箱有20到40英尺长，可以用远洋班轮、火车和公共道路上的集装箱底盘拖车运输。国内集装箱长达53英尺，结构更轻。'
            },
            {
              content: '<strong>Container Chassis</strong><br /> 一种专门用来装载集装箱的拖车。'
            },
            {
              content: '<strong>Consignee</strong><br /> 接收者，接受你的运输交付。'
            },
            {
              content: '<strong>Carrier Liability</strong><br /> 除因天灾、公敌行为、公共机关行为、托运人行为和货物的固有性质造成的损失、损坏和迟延外，承运人对货物的灭失、损坏和迟延承担责任。'
            },
            {
              content: '<strong>Class I Carrier</strong><br /> 根据每年超过500万美元的营业收入对承运人进行分类。'
            },
            {
              content: '<strong>Class II Carrier</strong><br /> 根据每年100万至500万美元的营业收入对承运人进行分类。'
            },
            {
              content: '<strong>Class III Carrier</strong><br /> 根据年收入低于100万美元对承运人进行分类。'
            },
            {
              content: '<strong>Cube</strong><br /> 拖车内部的容积，以立方英尺为单位。'
            },
          ],
          [
            {
              content: '<strong>Density</strong><br /> 货物重量与所占物理空间的关系。'
            },
            {
              content: '<strong>Drayage</strong><br /> 本地处理货物的费用。在运输业中很常见。'
            },
            {
              content: '<strong>Deadhead</strong><br /> 驾驶一辆没有货物或付费载货的牵引拖车。'
            },
            {
              content: '<strong>Detention/demurrage</strong><br /> 承运人对托运人或者收货人持有设备超过规定装卸时间的罚款。'
            },
            {
              content: '<strong>Dock Lock</strong><br /> 挂在拖车保险杠上的一种安全装置，当拖车倒车到装货码头时，它可以挂在保险杠上，以防止当叉车司机和其他人在拖车内时，拖车被拉开。'
            },
            {
              content: '<strong>Drop and Hook</strong><br /> 接收器，放下拖车，然后带着满载的拖车离开。'
            },
            {
              content: '<strong>Dry Freight</strong><br /> 没有冷藏的货物。'
            },
            {
              content: '<strong>Fingerprinting</strong><br /> 当司机不得不自己卸货时。'
            },
            {
              content: '<strong>Freight Lane</strong><br /> 大量货物往来的路线。经常被称为小巷。'
            },
            {
              content: '<strong>Free on board (FOB)</strong><br /> 卖方同意送货到合同规定的地点。'
            },
            {
              content: '<strong>FOB destination</strong><br /> 在此安排下，所有权和风险由卖方承担，直到卖方将货物交付到合同规定的地点为止。'
            },
            {
              content: '<strong>FOB origin</strong><br /> 所有权和风险在卖方将货物交付给承运人时转移给买方'
            },
            {
              content: '<strong>Freight bill</strong><br /> 装运单据一种装运单据，用来确认货物的交付，并指明付款条件(预付或托收)，用以描述货物。'
            },
            {
              content: '<strong>Freight Broker</strong><br /> 安排他人运输货物的公司，利用承租承运人提供实际运输。经纪人不承担货物的责任，通常也不占有货物。'
            },
            {
              content: '<strong>Freight forwarder</strong><br /> 货运代理将低于卡车载货量(LTL)或低于集装箱载货量(LCL)的货物组合成满载集装箱或卡车载货量。货运代理被指定为普通承运人，可以签发提单并承担货物责任。'
            },
            {
              content: '<strong>FCL (Full Container-Load)</strong><br /> 全集装箱装运是指托运人与整个集装箱的运输签订合同。绝大多数的多式联运货物都是以这种方式签订合同的。'
            },
          ],
          [
            {
              content: '<strong>GAWR (Gross Axle Weight Rating)</strong><br /> 轴的最大重量由制造商规定。'
            },
            {
              content: '<strong>GCW (Gross Combination Weight)</strong><br /> 一辆装载有货物的混合车辆的总重量，如一辆牵引式挂车。'
            },
            {
              content: '<strong>GVW (Gross Vehicle Weight)</strong><br /> 车辆的总重量;车辆的重量，以及拖车和牵引车的内容物。'
            },
            {
              content: '<strong>GVWR (Gross Vehicle Weight Rating)</strong><br /> 由制造商评定的车辆总重量，包括车辆本身的重量和货物的重量。'
            },
            {
              content: '<strong>Hazmat</strong><br /> 美国环境保护局(EPA)分类的有害物质。危险物质的运输由美国交通部管理。'
            },
            {
              content: '<strong>Hundredweight / CWT</strong><br /> 100磅。一种常用的装运重量单位。'
            },
            {
              content: '<strong>Intermodal Shipping</strong><br /> 一种使用标准尺寸集装箱的系统，可以在不同的运输方式之间移动，如船舶、卡车和火车。货物不需要每次从一种模式移动到另一种模式时卸载。'
            },
            {
              content: '<strong>Inside Pickup / Inside Delivery</strong><br /> 如果司机被要求进入(超过前门或装货码头)，取货或送货，而不是停留在码头或卡车上，这被认为是内部取货或送货。'
            },
            {
              content: '<strong>Jake Brake&nbsp;</strong><br /> 一种发动机缓速器，帮助车辆减速。'
            },
            {
              content: '<strong>LCL (Less-than-Container-Load)</strong><br /> 海运和多式联运工业术语;集装箱运输中的LTL等价。港口集装箱货运站是集装卸料码头。从历史上看，LCL也代表小于汽车载重量。在州际公路货运兴起之前，铁路公司提供的是拼箱运输(LCL)服务，但这项业务已基本消失。'
            },
            {
              content: '<strong>Landing Gear</strong><br /> 当拖车没有与拖拉机连接时，支撑它的伸缩腿。'
            },
            {
              content: '<strong>Layovers</strong><br /> 任何不在家的非工作时间。'
            },
            {
              content: '<strong>Loaded Call</strong><br /> 拖车装载完毕并签好账单后，从发货人的位置向调度员发出的呼叫。'
            },
            {
              content: '<strong>Lift Gate Service</strong><br /> 当发货或收货地址没有装货码头时，需要手动装卸。升降闸门是位于某些卡车后部的一个平台，它可以将货物从地面升降到卡车上。这项服务需要额外的费用。'
            },
            {
              content: '<strong>Linehaul</strong><br /> 把货物从一个地方运到另一个地方。'
            },
            {
              content: '<strong>LTL (Less-Than-Truckload)</strong><br /> 低于应用卡车载货率所需的货物数量，通常少于10,000磅。这些较小的负载由一艘LTL航空母舰合并成一艘前往多个目的地的运载工具。'
            },
            {
              content: '<strong>LCV (Long Combination Vehicle)</strong><br /> 一辆卡车和两辆或两辆以上的拖车的任何组合，在州际公路上行驶，总车辆重量(GVW)超过80,000磅。'
            },
            {
              content: '<strong>Low Boy</strong><br /> 一种敞开式平板拖车，其主体离地面很低，以便能牵引超大型或宽负荷;通常是建筑设备，或其他非常笨重或沉重的负荷。'
            },
          ],
          [
            {
              content: '<strong>Minimum charge</strong><br /> 折扣和/或调整后的最低运费。'
            },
            {
              content: '<strong>NMFC Number</strong><br /> 这是一个项目编号，专门标识可以由LTL承运人运输的每种类型的产品。美国国家汽车货运协会(NMFTA)将NMFC项目编号s分配给所有产品以及LTL货运类别。NMFC # 标识您要发送的内容及其LTL货运类。'
            },
            {
              content: '<strong>Non-vessel operating common carriers (NVOCC)</strong><br /> 海洋货运代理 无船承运人以较低的价格大量预订舱位,然后以较小的金额向托运人出售舱位'
            },
            {
              content: '<strong>No-Touch</strong><br /> 司机不需要装卸货物的情况。'
            },
            {
              content: '<strong>Overcharge Claim</strong><br /> 因错误地适用运价、重量和/或运费估价而产生的多收费用的退款要求。'
            },
            {
              content: '<strong>Out of Route</strong><br /> 航空公司通常使用固定的里程来表示城市之间的距离。如果司机超过了这个数字，超过的任何里程都被认为是“出线”里程。'
            },
            {
              content: '<strong>Overage</strong><br /> 额外的运费，不应该被装运。'
            },
            {
              content: '<strong>Owner-Operator&nbsp;</strong><br /> 自己做生意的司机，拥有并经营自己的卡车、拖车或其他设备。'
            },
            {
              content: '<strong>Pallet / Skid</strong><br /> 一种小平台，通常为40×48英寸，货物放在上面装卸和运输。'
            },
            {
              content: '<strong>P&D</strong><br /> 提柜和交付。'
            },
            {
              content: '<strong>Pay Load</strong><br /> 装运货物的重量。'
            },
            {
              content: '<strong>PRO or Tracking Number</strong><br /> 一个由承运人指定的号码，以参考你方的货物。它也用于跟踪您的发货。'
            },
            {
              content: '<strong>Peddle Run</strong><br /> 有多个且经常频繁交付的负载。'
            },
          ],
          [
            {
              content: '<strong>Reweigh and Inspection Fee</strong><br /> 如果承运人怀疑你的货物的重量或等级不准确，他们将收取费用重新称重和重新分类你的货物。'
            },
            {
              content: '<strong>Reefer</strong><br /> 由制冷装置控制温度的冷藏拖车。'
            },
            {
              content: '<strong>Seal</strong><br /> 一根塑料或金属带(一旦断裂，就不能重新接上)放在拖车的门闩上，以确保车门没有被打开，货物也没有被碰过。'
            },
            {
              content: '<strong>Spotter</strong><br /> 把拖车停在终点站院子里的司机。'
            },
            {
              content: '<strong>Shipper / Consignor</strong><br /> 付运费从一个地方到另一个地方的运费的人或组织。'
            },
            {
              content: '<strong>Shortage</strong><br /> 收到的数量少于装运单据上显示的数量。'
            },
            {
              content: '<strong>3PL</strong><br /> 第三方物流公司，物流服务外包给第三方物流公司。通常处理货物的采购、调度和计费。'
            },
            {
              content: '<strong>Tare Weight</strong><br /> 容器和包装材料的重量减去其内容物的重量。'
            },
            {
              content: '<strong>Tariff</strong><br /> 运货合同一份详细说明运货规则、运价和收费的文件，并为托运人、收货人和承运人订立合同。承运人不需要公布关税，但必须根据托运人的要求提供'
            },
            {
              content: '<strong>TL (Truckload)</strong><br /> 足够装满一辆拖车的货物数量，通常超过10,000英镑。'
            },
            {
              content: '<strong>TL Carrier</strong><br /> 将拖车专用于单个托运人的货运的承运人，而不是经常运输多个不同托运人的联合货运的LTL承运人。'
            },
            {
              content: '<strong>Volume Rate</strong><br /> 与规定的货物体积(重量)有关的费率。'
            },
          ],
        ],
        usModel: '美国多式联运',
        modelDesc: '下面列出了至少有两个多式联运码头的城市以及运营这些设施的铁路。有些可能是联合(或重复)设施。更新以粗体显示。',
      },
    }
  }
}
