<template>
  <footer class='visitor'>
    <section>
      <div class='wrap'>© 2019 Look4Trucks Inc. </div>
    </section>
  </footer>
</template>
<script>
export default {
  name: 'Footer',
  data(){
    return {

    }
  }
}
</script>
