<template>
  <div id="app">
    <router-view />
    <vue-progress-bar />
  </div>
</template>

<script>
export default {
  name: "app",
};
</script>
