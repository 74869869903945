import Vue from "vue";
import VueLodash from "vue-lodash";
import lodash from "lodash";
Vue.use(VueLodash, { lodash: lodash });

Object.assign(String.prototype, {
  titleize() {
    return this.split(/\s|-|_/)
      .map((val) => {
        return Vue._.capitalize(val);
      })
      .join(" ");
  },
  isBlank() {
    return /^\s*$/.test(this);
  },
});
