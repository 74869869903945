export default {
  methods: {
    lastActivityExpired() {
      const expireTime = 1000 * 60 * 30; // 30 mins
      const lastTime = new Date(
        parseInt(localStorage.getItem("lastActivity")) || null
      );
      const currTime = new Date();
      return currTime - lastTime > expireTime;
    },
    setJwtToken(token) {
      localStorage.setItem("jwt-token", token);
    },
    async currentUser() {
      if (["password"].includes(this.$route.name)) return;
      const response = await this.$http.get('/api/v1/users/profile');
      if (response.status == 200) {
        this.$actions.setAuthed(true);
        this.$actions.setUser(response.data);
      } else {
        this.signOut();
        throw new Error("401 Unauthorized");
      }
    },
    signOut() {
      this.$actions.setAuthed(false);
      this.$actions.setUser({});
      localStorage.removeItem('jwt-token');
      localStorage.removeItem("lastActivity");
    },
    async loadUsers() {
      try {
        return (await this.$http.get('/api/v1/users')).data;
      } catch (ex) {
        this.$message.error(ex);
      }
    }
  }
}
