<template>
  <header>
    <div class="wrap">
      <div id="hamburger" v-on:click="displayMenu()">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <a href="/"><img src="@/assets/logo.png" alt="logo"></a>
      <div class='nav'>
        <div id='langs'>
          <a v-for="item in languages" :key="item.language" @click="changeLocale(item.language)" :class="{ active: activeLocale(item) }">
            {{item.title}}
          </a>
        </div>
        <ul id="menu">
          <li><a href='/#company' @click="clickLink">{{ $t('message.menu.company') }}</a></li>
          <li><a href='/#why-us' @click="clickLink">{{ $t('message.menu.whyUs') }}</a></li>
          <li><a href='/#mission' @click="clickLink">{{ $t('message.menu.mission') }}</a></li>
          <li><a href='/#contact' @click="clickLink">{{ $t('message.menu.contact') }}</a></li>
          <li><a href='/#resources' @click="clickLink">{{ $t('message.menu.resources') }}</a></li>
          <li><a href="/console">{{ $t('message.menu.signIn') }}</a></li>
          <!-- <li class="drop">
            <a v-on:click="displayDropMenu()">
              Menu Dropdown
              <i class="icon-arrow"></i>
            </a>
            <ul class="drop-menu">
              <a>Sub menu 1</a>
              <a>Sub menu 2</a>
              <a>Sub menu 3</a>
            </ul>
          </li> -->
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Menu',
  data(){
    return {
      languages: [
        { language: 'en-US', title: 'English' },
        { language: 'zh-CN', title: '中文' }
      ]
    }
  },
  mounted(){
    document.getElementsByTagName("body")[0].classList.add("imenu");
    window.addEventListener("resize", this.resize);
    window.addEventListener('scroll', this.scroll);
  },
  destroyed(){
    document.getElementsByTagName("body")[0].classList.remove("imenu");
    window.removeEventListener('resize', this.resize);
    window.removeEventListener('scroll', this.scroll);
  },
  methods: {
    activeLocale(item){
      return this.$i18n.locale == item.language;
    },
    changeLocale(locale) {
      localStorage.setItem('locale', locale);
      this.$i18n.locale = locale;
    },
    resize: function(){
      this.closeAllMenus();
      document.getElementsByTagName("body")[0].classList.remove("display-menu");
    },
    scroll: function(){
      this.closeAllMenus();
    },
    clickLink: function(){
      const body = document.getElementsByTagName("body")[0];
      if(body.classList.contains("display-menu")){
        body.classList.remove("display-menu")
      }
    },
    closeAllMenus: function(){
      [...document.getElementById("menu").getElementsByTagName("li")].forEach(e => e.style.marginTop = 0);
      [...document.getElementsByClassName("drop-menu")].forEach(e => e.classList.remove("display"));
    },
		displayMenu: function(){
      (document.getElementsByTagName("body")[0]).classList.toggle("display-menu");
		},
		displayDropMenu: function(){
			var dropMenu = event.target.parentElement.getElementsByClassName("drop-menu")[0];
			[...document.getElementsByClassName("drop-menu")].filter(e => e != dropMenu).forEach(e => e.classList.remove("display"));
			[...document.getElementById("menu").getElementsByTagName("li")].forEach(e=> e.style.marginTop = 0);
      dropMenu.classList.toggle("display");
			if(window.innerWidth < 660 && dropMenu.classList.contains("display")) {
				event.target.parentElement.nextSibling.style.marginTop = dropMenu.clientHeight + "px";
			}
		}
	}
}
</script>
