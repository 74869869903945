<template>
  <section class='resources'>
    <div class='wrap'>
      <a class='anchor' name='resources'/>
      <h2 class='center'>{{ $t('message.menu.resources') }}</h2>
      <el-tabs v-model="forResource">
        <el-tab-pane :label="$t('message.resources.shippingTerms')" name="tab1" class="shipping-terms">
          <el-carousel :interval="4000" indicator-position="outside">
            <el-carousel-item v-for="(carousel, index) in $t('message.resources.termItems')" :key="index">
              <template v-for="(item, index) in carousel">
                <p v-html="item.content" :key="index"></p>
                <ul class="arrow" v-if="item.asItems" :key="item.content">
                  <li v-for="(as, index) in item.asItems" :key="index">{{as}}</li>
                </ul>
              </template>
            </el-carousel-item>
          </el-carousel>
        </el-tab-pane>
        <el-tab-pane :label="$t('message.resources.usModel')" name="tab2">
          <p v-html="$t('message.resources.modelDesc')"></p>
          <el-row :gutter="30">
            <el-col :xs="24" :sm="24" :md="12">
              <div class="rails"/>
              <ul class='rail-list'>
                <li>BNSF (Burlington Northern Santa Fe)</li>
                <li>CN (Canadian National &ndash; includes KC Southern)</li>
                <li>CP (Canadian Pacific)</li>
                <li>CSX (CSX)</li>
                <li>KCS (Kansas City Southern)</li>
                <li>NS (Norfolk Southern)</li>
                <li>UP (Union Pacific)</li>
              </ul>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12">
              <ul class="hub-list">
                <li>Chicago (<b>21</b>): BNSF(4), CN (2), CP (2), CSX (<b>3</b>), NS (5) and UP (5)</li>
                <li>New York City (8): CSX (4) and NS (4)</li>
                <li>Los Angeles (6): BNSF (2) and UP (4)</li>
                <li>Memphis (6): BNSF, CSX, CN, NS (2) and UP</li>
                <li>Seattle-Tacoma (6): BNSF (4) and UP (2)</li>
                <li>Detroit (5): CSX, CN, CP and NS (2)</li>
                <li>Houston (5): UP (3) and BNSF (2)</li>
                <li>Montreal (5): CSX, CN, CP (2) and NS</li>
                <li>Toronto (5): CN (2), CP (2) and NS</li>
                <li>Atlanta (4): CSX (2) and NS (2)</li>
                <li>Dallas-Fort Worth (4): BNSF, KCS&nbsp;and UP (2)</li>
                <li>Hampton Roads (4): NS (3) and CSX</li>
                <li>Kansas City(4): BNSF, CSX, NS and UP</li>
                <li>New Orleans &nbsp;(4): BNSF, CN, CSX and UP</li>
                <li>Louis (4): BNSF, CSX, NS and UP</li>
                <li>Cincinnati (3): CSX and NS (2)</li>
                <li>Columbus, OH (3) CSX (2) and NS</li>
                <li>Louisville (3): CSX and NS (2)</li>
                <li>Twin Cities (3): BNSF, CN, and CP</li>
                <li>Worcester (3): CSX (2) and CN</li>
                <li>Baltimore (2): CSX and NS</li>
                <li>Birmingham (2) CSX and NS</li>
                <li>Buffalo (2) CSX and NS</li>
                <li>Calgary (2): CN and CP</li>
                <li>Charleston, SC (2) CSX and NS</li>
                <li>Charlotte (2) CSX and NS</li>
                <li>Cleveland (2): CSX and NS</li>
                <li>Denver (2): BNSF and UP</li>
                <li>Edmonton (2): CN and CP</li>
                <li>El Paso (2): BNSF and UP</li>
                <li><b>Hagerstown-Chambersburg (2): CSX and NS</b></li>
                <li>Jacksonville (2) CSX and NS</li>
                <li>Oakland (2): BNSF and UP</li>
                <li>Omaha (2): BNSF and UP</li>
                <li>Philadelphia (2): NS (2)</li>
                <li>Portland, OR (2): BNSF and UP</li>
                <li>Saskatoon (2): CN and CP</li>
                <li>Savannah (2) CSX and NS</li>
                <li>Stockton, CA (2): BNSF and UP</li>
                <li>Toledo (2) CSX and NS</li>
                <li>Vancouver (2): CN and CP</li>
                <li>Winnipeg (2): CN and CP</li>
              </ul>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </div>
  </section>
</template>
<script>
export default {
  name: 'Resources',
  data(){
    return {
      forResource: 'tab1'
    }
  },
  mounted(){
  },
  destroyed(){
  },
  methods: {
	}
}
</script>
