import axios from "axios";
import qs from "qs";
import Main from '../main';
import { Message } from "element-ui";

const API_URL = process.env.VUE_APP_API_URL;
const Axios = axios.create({
  baseURL: API_URL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
});

Axios.interceptors.request.use(
  async (config) => {
    Main.$Progress.start();
    localStorage.setItem("lastActivity", new Date().valueOf());
    const jwtToken = localStorage.getItem("jwt-token");
    if (jwtToken != null)
      config.headers["Authorization"] = `Bearer ${jwtToken}`;
    config.paramsSerializer = (params) => {
      return qs.stringify(params, {
        arrayFormat: "brackets",
        encode: false,
      });
    };
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (response) => {
    Main.$Progress.finish();
    // validateStatus(status) is true
    return response;
  },
  (error) => {
    Main.$Progress.finish();
    // validateStatus(status) is false
    if (error && error.response) {
      switch (error.response.status) {
        // case 400:
        //   error.message = 'Bad Request'
        //   break;
        case 401:
          Main.signOut();
          break;
        case 403:
          error.message = "Forbidden";
          Message.error(error.response.data.errors);
          break;
        // case 404:
        //   error.message = 'Not Found'
        //   break;
        // case 405:
        //   error.message = 'Method Not Allowed'
        //   break;
        // case 408:
        //   error.message = 'Request Timeout'
        //   break;
        case 422:
          var _errors = error.response.data.errors;
          if (typeof _errors == "string") {
            error.response.data.errors = _errors;
          } else {
            Object.keys(_errors).forEach((key) => {
              _errors[key] = _errors[key].join(", ");
            });
            error.response.data.errors = _errors;
          }
          break;
        // case 500:
        //   error.message = 'Internal Server Error'
        //   break;
        // case 501:
        //   error.message = 'Not Implemented'
        //   break;
        // case 502:
        //   error.message = 'Bad Gateway'
        //   break;
        // case 503:
        //   error.message = 'Service Unavailable'
        //   break;
        // case 504:
        //   error.message = 'Gateway Timeout'
        //   break;
        // case 505:
        //   error.message = 'HTTP Version Not Supported'
        //   break;
        default:
          error.message = `Connection Error: ${error.response.status}`;
      }
    } else {
      error.message = "Failed to connect to server";
    }
    return Promise.reject(error.response);
  }
);

export default {
  mergeQueryParams(params, queryParams) {
    if (Object.getOwnPropertyNames(queryParams).length > 0) {
      Object.keys(queryParams).forEach((key) => {
        if (typeof queryParams[key] === 'string') {
          params["q[" + key + "]"] = encodeURIComponent(queryParams[key]);
        } else {
          params["q[" + key + "]"] = queryParams[key];
        }

      });
    }

    return params;
  },
  get(url, params) {
    return Axios.get(url, { params: params })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  post(url, params) {
    return Axios.post(url, params)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  upload(url, name, blob) {
    const formData = new FormData();
    formData.set(name, blob);
    return Axios.put(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  uploadPost(url, name, blob, params) {
    const formData = new FormData();
    formData.set(name, blob);
    if (params) {
      Object.keys(params).forEach((key) => {
        formData.set(key, params[key]);
      });
    }
    return Axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  put(url, params) {
    return Axios.put(url, params)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  patch(url, params) {
    return Axios.patch(url, params)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  delete(url) {
    return Axios.delete(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  download(url, params) {
    return Axios({
      url: url,
      method: "GET",
      params: params || {},
      responseType: "blob",
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  downloadPost(url, params) {
    return Axios({
      url: url,
      method: "POST",
      data: params,
      responseType: "blob",
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
};
