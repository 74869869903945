import Vue from 'vue'
import App from './App.vue'
import VueI18n from 'vue-i18n';
import ElementUI from 'element-ui';
import VueProgressBar from 'vue-progressbar';
import router from '@/route'
import http from '@/utils/http';
import "@/utils/core";
import '@/main.scss';
import "@/stores";
import user_utils from "@/utils/user_utils";
Vue.mixin({
  mixins: [user_utils],
  methods: {
    isEmpty(data) {
      return this._.isEmpty(data);
    },
    async loadHubs() {
      try {
        return (await this.$http.get("/api/v1/options?target=hubs")).data;
      } catch (ex) {
        this.$message.error(ex);
      }
    },
  }
});
Vue.config.productionTip = false
Vue.prototype.$http = http;
Vue.use(VueI18n);
Vue.use(VueProgressBar, { color: '#c46200', failedColor: '#ff4747', thickness: '5px' })
Vue.use(ElementUI);

let echarts = require('echarts/lib/echarts');
require('echarts/lib/chart/line');
require("echarts/lib/component/legendScroll");
require("echarts/lib/component/tooltip");
require("echarts/lib/component/axisPointer");
Vue.prototype.$echarts = echarts;

import messages from '@/messages';
import enLocale from 'element-ui/lib/locale/lang/en';
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
import ElementLocale from 'element-ui/lib/locale';
const fullMessages = {
  'en-US': {
    ...messages['en-US'],
    ...enLocale
  },
  'zh-CN': {
    ...messages['zh-CN'],
    ...zhLocale
  }
}
const locale = localStorage.getItem('locale') || navigator.language;
localStorage.setItem('locale', locale);
const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: 'en-US', // set fallback locale
  messages: fullMessages // set locale messages
})
ElementLocale.i18n((key, value) => i18n.t(key, value))

export default new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')

////////////////////////BEGIN Vue Filters ////////////////////////
Vue.filter('withDelimiter', function (value, options = { min: 2, max: 2 }) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: options.min,
    maximumFractionDigits: options.max
  });
  return formatter.format(value);
});

Vue.filter("toCurrency", function (value, options = { cur: "USD", min: 2, max: 2 }) {
  if (!["number", "string"].includes(typeof value)) {
    return value;
  }
  if (!value.toString().match(/^-?\d*\.?\d*$/)) {
    return value;
  }
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: options.cur,
    minimumFractionDigits: options.min,
    maximumFractionDigits: options.max,
  });
  return value < 0
    ? `(${formatter.format(Math.abs(value))})`
    : formatter.format(value);
});
Vue.filter("usFormat", function (value, options = { year: true }) {
  if (!["string"].includes(typeof value)) {
    return value;
  }
  let date = value.match(/\d+-\d+-\d+/);
  if (date == null || date.length == 0) {
    return value;
  }
  let each = date[0].split("-");
  let parts = [each[1], each[2]];
  if (options.year) parts.push(each[0]);
  return parts.join("/") + value.replace(date[0], "");
});
////////////////////////END Vue Filters ////////////////////////
