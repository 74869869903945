<template>
  <main class='visitor'>
    <section class='b1' data-aos="zoom-in" data-aos-duration="500">
      <el-row class='wrap'>
        <center>
          <p class="h1" data-aos="fade-up" data-aos-duration="1000">{{ $t('message.banner.title') }}</p>
          <a class='start' href="/console" data-aos="fade-right">{{ $t('message.banner.start')}}</a>
        </center>
      </el-row>
    </section>
    <section>
      <div class='wrap'>
        <a class='anchor' name='company'/>
        <h2 class='center'>{{ $t('message.menu.company') }}</h2>
        <el-row :gutter="40">
          <el-col :xs="24" :sm="24" :md="12">
            <p data-aos="fade-right">{{ $t('message.company.p1') }}</p>
            <p data-aos="fade-left">{{ $t('message.company.p2') }}</p>
            <p data-aos="fade-right">{{ $t('message.company.p3') }}</p>
            <p data-aos="fade-left">{{ $t('message.company.p4') }}</p>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12">
            <div class="b2" data-aos="fade-left" data-aos-offset="200" data-aos-duration="1000"/>
          </el-col>
        </el-row>
      </div>
    </section>
    <section class='why-us'>
      <div class='wrap'>
        <a class='anchor' name='why-us'/>
        <h2 class='center'>{{ $t('message.menu.whyUs') }}</h2>
        <p>{{ $t('message.whyUs.p1') }}</p>
        <el-tabs v-model="forWho">
          <el-tab-pane :label="$t('message.whyUs.forAgent')" name="agent">
            <el-row :gutter="30">
              <el-col :xs="24" :sm="24" :md="12" data-aos="fade-down" data-aos-duration="1000">
                <ul>
                  <li v-for="(item, index) in $t('message.whyUs.list1')" :key="index">{{item}}</li>
                </ul>
                <em>{{ $t('message.whyUs.p2') }}</em>
                <ul>
                  <li v-for="(item, index) in $t('message.whyUs.list2')" :key="index">{{item}}</li>
                </ul>
              </el-col>
              <el-col :xs="24" :sm="24" :md="12">
                <div class="b3"  data-aos="fade-left" data-aos-offset="200" data-aos-duration="1000"/>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane :label="$t('message.whyUs.forCarrier')" name="carriers">
            <el-row :gutter="30">
              <el-col :xs="24" :sm="24" :md="12"  data-aos="fade-down" data-aos-duration="1000">
                <ul>
                  <li v-for="(item, index) in $t('message.whyUs.list3')" :key="index">{{item}}</li>
                </ul>
              </el-col>
              <el-col :xs="24" :sm="24" :md="12">
                <div class="b4" data-aos="fade-left" data-aos-offset="200" data-aos-duration="1000"/>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </div>
    </section>
    <section class='mission'>
      <div class='wrap'>
        <a class='anchor' name='mission'/>
        <h2 class='center'>{{ $t('message.menu.mission') }}</h2>
        <el-row :gutter="20" class="level">
          <template v-for="(item, index) in $t('message.mission.paragraph')">
            <el-col :xs="24" :sm="24" :md="8" :key="index">
              <p data-aos="zoom-in">{{item}}</p>
            </el-col>
          </template>
        </el-row>
      </div>
    </section>
    <section class='contact'>
      <div class='wrap'>
        <a class='anchor' name='contact'/>
        <h2 class='center'>{{ $t('message.menu.contact') }}</h2>
        <center data-aos="flip-down">
          <p>{{ $t('message.contact.email') }}: <a hrer="mailto:quotes@look4trucks.com">quotes@look4trucks.com</a></p>
          <p>{{ $t('message.contact.address') }}: {{address}}</p>
          <p>{{ $t('message.contact.tel') }}: <a href="tel:+16263340100">+1 626-334-0100</a></p>
          <iframe width="100%" height="600" frameborder="0" style="border: 5px solid #FFF" :src="mapUrl" allowfullscreen></iframe>
        </center>
      </div>
    </section>
    <Resources/>
  </main>
</template>
<script>
import Resources from '@/views/visitor/Resources.vue';
export default {
  name: 'Main',
  components: {
    Resources
  },
  data(){
    return {
      forWho: 'agent',
      address: "16059 E Foothill Blvd, Irwindale, CA 91702",
      googleMapKey: process.env["VUE_APP_GOOGLE_MAP_KEY"]
    }
  },
  computed: {
    mapUrl(){
      return `https://www.google.com/maps/embed/v1/place?q=${this.address.replace(/\s/g, '+')}&key=${this.googleMapKey}`;
    }
  }

}
</script>
