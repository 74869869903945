<template>
  <section>
    <Menu></Menu>
    <Main></Main>
    <Footer></Footer>
  </section>
</template>
<script>
import Menu from '@/views/visitor/Menu.vue';
import Main from '@/views/visitor/Main.vue';
import Footer from '@/views/visitor/Footer.vue';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  name: 'Home',
  components: {
    Menu,
    Main,
    Footer
  },
  data(){
    return {
    }
  },
  created(){
    AOS.init()
  },
  mounted(){
  },
  destroyed(){
  },
  methods: {
	}
}
</script>
