import Vue from "vue";

const store = Vue.observable({
  authed: (localStorage.getItem("jwt-token") || "").length > 0,
  user: JSON.parse(localStorage.getItem("user") || "{}"),
  formerUrl: "",
  intervals: {},
  hubs: JSON.parse(localStorage.getItem("hubs") || "[]"),
});

const actions = {
  setAuthed(data) {
    store.authed = data;
  },
  setUser(data) {
    const { id, name, email } = data;
    store.user = { id, name, email };
    localStorage.setItem("user", JSON.stringify(store.user));
  },
  setFormerUrl(data) {
    store.formerUrl = data;
  },
  setInterval(data) {
    store.intervals = { ...store.intervals, ...data };
  },
  setHubs(data) {
    store.hubs = data;
    localStorage.setItem("hubs", JSON.stringify(store.hubs));
  },
};

Vue.prototype.$store = store;
Vue.prototype.$actions = actions;
